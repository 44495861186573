@use "../../../styles/palette";

.box {
  max-width: 50rem;
  width: 100%;
  height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 86px 1.25rem 1.25rem 1.25rem;
  z-index: 3;
  text-align: center;

  @media (max-width: 47.98rem) {
    & {
      flex-direction: column;
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 0.875rem;
    color: palette.$grey;
    margin-bottom: 3rem;
  }
}
