/*
    Global stylesheet of the app. Imported by src/index.js
*/

@use "shadow";
@use "palette";
@use "spacing";

@use "twiik-coach-theme";
@use "@carbon/react/scss/theme" with (
  $theme: twiik-coach-theme.$theme
);

@use "@carbon/styles/scss/components/button/tokens" with (
  $button-primary: palette.$blue,
  $button-primary-active: palette.$blue-active,
  $button-primary-hover: palette.$blue-hover,
  $button-secondary: palette.$background,
  $button-secondary-active: palette.$blue-active,
  $button-secondary-hover: palette.$blue-hover
);

@use "@carbon/react/scss/grid";
@use "@carbon/react/scss/reset";
@use "@carbon/react/scss/layer";

@use "@carbon/react/scss/components/button";
@use "@carbon/react/scss/components/link";
@use "@carbon/react/scss/components/loading";
@use "@carbon/react/scss/components/ui-shell";
@use "@carbon/react/scss/components/dropdown";
@use "@carbon/react/scss/components/checkbox";

@include grid.flex-grid();
@include shadow.generateShadow();
@include dropdown.dropdown;
@include checkbox.checkbox;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "./flex";
@import "./modifications";

// Global styles

body {
  font-family: "Inter";
}

.tc-card {
  @include shadow.shadow(4);
  background-color: palette.$white;
  padding: 1rem;
  border-radius: 1.5rem;
  overflow: hidden;

  &.no-shadow {
    box-shadow: none;
  }
}

.tc-middle-row {
  max-width: 42rem;
  margin: auto;
}

.tc-thin-divider {
  width: 100%;
  height: 1px;
  background-color: palette.$grey-light;
  margin: 0.5rem 0;
}

.tc-column {
  margin-bottom: 2rem;
}

.tc-helper-text {
  font-size: 0.875rem;
  color: palette.$grey;
}

.tc-row-list {
  padding: 1rem;

  .tc-row-list-key {
    text-transform: uppercase;
    color: palette.$grey;
    font-size: 0.75rem;
    font-weight: 600;
    padding-top: 0.125rem;

    @media (max-width: 65.98rem) {
      margin-bottom: 1rem;
    }
  }

  .tc-row-list-value {
    font-size: 0.875rem;
    line-height: normal;
    // padding: 0.5rem 1rem;
  }

  .tc-row-list-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 65.98rem) {
      margin-top: 1rem !important;
    }
  }

  @media (max-width: 41.98rem) {
    .tc-row-list-action {
      margin-top: 0.5rem;
      justify-content: flex-start;
    }
  }
}

.tc-empty {
  font-size: 0.875rem;
  font-style: italic;
  color: palette.$grey;
}

.tc-bottom {
  background-color: palette.$grey-light;
  height: 1.5rem;
  width: 100%;
}

.tc-btn-centered {
  padding: calc(0.675rem - 3px) 31.5px calc(0.675rem - 3px) 31.5px;
}

.tc-center-btn {
  justify-content: center;
  min-width: 9rem;
}

.tc-lg-btn-center {
  padding: calc(0.875rem - 3px) 31.5px calc(0.875rem - 3px) 31.5px;
}

button.tc-fw-button.cds--btn {
  width: 100%;
  max-width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  background-color: palette.$off-white;
  font-weight: 600;

  svg {
    margin-right: 0.5rem;
    stroke-width: 1;
    stroke: palette.$blue;
  }

  &:hover {
    background-color: palette.$blue;

    svg {
      stroke-width: 1;
      stroke: palette.$white;
    }
  }
}

.tc-divider-thin {
  height: 1px;
  background: palette.$grey-light;
}

.tc-divider {
  width: 45%;
  max-width: 120px;
  height: 0.45rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  background-color: palette.$blue;

  &.tc-coach-bg {
    background-color: palette.$coach;
  }

  &.tc-library-bg {
    background-color: palette.$library;
  }

  &.tc-company-bg {
    background-color: palette.$company;
  }

  &.tc-channel-bg {
    background-color: palette.$channel;
  }
}

.tc-bold {
  font-weight: 600;
}

.tc-txt-success {
  color: palette.$green;
}

.tc-status {
  vertical-align: middle;
  background-color: palette.$white;
  border-radius: 50%;

  &.active {
    fill: palette.$green;
    margin-right: 0.5rem;
  }
  &.passive {
    fill: palette.$red;
    margin-right: 0.5rem;
  }
}

.tc-asterisk {
  color: palette.$red;
}

.tc-dropdown-c .cds--dropdown * {
  text-transform: capitalize;
}

.tc-modal-flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tc-modal-header-close {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.2;
    }
  }
}

.tc-textarea-error {
  textarea.cds--text-area {
    border: 1px solid palette.$red;
  }
  .cds--form__helper-text {
    color: palette.$red;
  }
}

.tc-warn-text {
  color: palette.$red;
}

// Modifications

button.tc-btn-borderless.cds--btn {
  border: none;

  &:hover {
    border: none;
  }
}

.tc-double-input {
  display: flex;
  justify-content: space-between;

  .cds--form-item,
  .cds--dropdown__wrapper {
    max-width: 49%;
    width: 100%;
  }
}

.tc-dropdown-up .cds--dropdown--open {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tc-dropdown-bottom .cds--dropdown--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

button.cds--btn.tc-rigid-btn {
  border-radius: 0.25rem;
}

.tc-btn-icon.cds--btn {
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.cds--btn--sm {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  &.cds--btn--md {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
  }

  svg {
    left: 0;
    right: 0;
    margin: auto;
  }
}

.tc-link-danger {
  color: palette.$red;
  transition: all 0.11s cubic-bezier(0.2, 0, 1, 0.9);

  &:hover {
    color: palette.$red;
    opacity: 0.6;
  }
}

.tc-capitalize {
  text-transform: capitalize;
}

.tc-fix-grid {
  @media (min-width: 42rem) {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
}

.tc-description-text {
  color: palette.$grey;
  font-size: 0.875rem;
}

.tc-block {
  display: block;
}

.tc-fw {
  width: 100% !important;
}

.tc-white-text {
  color: palette.$white;
}

.tc-btn-fw.cds--btn {
  width: 100%;
  max-width: unset;
  border-radius: 0.25rem;
  justify-content: center;
}

@include spacing.staticSpacing();
@include spacing.spacingGen("mt", margin-top);
@include spacing.spacingGen("mb", margin-bottom);
@include spacing.spacingGen("mr", margin-right);
@include spacing.spacingGen("ml", margin-left);
@include spacing.spacingGen("p", padding);
@include spacing.spacingGen("pb", padding-bottom);
@include spacing.spacingGen("pt", padding-top);
