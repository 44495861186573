@use "../../../styles/palette";

:global {
  .cds--side-nav.cds--side-nav--expanded ~ main.cds--content {
    margin-left: 3rem;
  }
}

main.content {
  margin-left: 3rem;
  min-height: 100vh;
  background-color: palette.$background;
  transition: all 0.11s cubic-bezier(0.2, 0, 1, 0.9);

  &.contentLock {
    margin-left: 16rem !important;
  }

  :global(.cds--grid) {
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 66rem) {
    & {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 65.98rem) {
    &,
    &.contentLock {
      margin-left: 0 !important;
    }
  }
}
