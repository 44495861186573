@use "../../styles/palette";

.container {
  margin: -2rem;
  padding: 2rem;
  height: 100vh;
  overflow: hidden;
}

.headlineTop {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > span {
    width: 100%;
  }
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumbs {
  display: flex;
  max-width: 300px;

  span:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.title {
  height: 40px;
  max-width: 350px;
}

.description {
  max-width: 600px;
}
