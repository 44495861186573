/*
    Color palette of the app
*/

$background: rgb(244, 246, 252);
$background-hover: rgb(229, 233, 248);

$white: rgb(255, 255, 255);
$off-white: #f4f4f4;
$red: rgb(206, 62, 63);
$black: rgba(22, 22, 22, 1);
$blue: rgb(73, 54, 243);
$blue-active: rgb(0, 41, 157);
$blue-hover: rgb(45, 28, 197);
$blue-light: rgba(75, 57, 244, 0.1);
$grey: rgb(149, 154, 170);
$grey-light: rgba(230, 230, 237, 1);
$grey-dark: rgba(96, 96, 99, 1);
$aqua: rgb(188, 230, 255);
$aqua-dark: rgba(89, 197, 219, 1);
$orange: #f87d0b;
$green: rgba(88, 191, 61, 1);
$purple: rgba(75, 57, 244, 0.05);
// $green: rgb(37, 162, 69);

$coach: rgb(87, 198, 220);
$channel: rgb(224, 1, 3);
$company: rgb(246, 198, 43);
$library: rgb(4, 155, 55);
$admin: rgb(91, 27, 203);
