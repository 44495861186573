// Build shadows on demand for inside use
@mixin shadow($size) {
    box-shadow: 0 1px * $size 2px * $size 0 rgba(0,0,0,0.2);
}

// Generates predefined shadow classes for outside use
@mixin generateShadow {
    $size: 1;
    @while $size <= 8 {
        .shadow-x#{$size} {
            @include shadow($size)
        }
        $size: $size * 2;
    }
}
