button.cds--overflow-menu {
  background-color: palette.$blue;
  border-radius: 1rem;

  &:hover {
    background-color: palette.$blue-hover;
  }

  &:active {
    background-color: palette.$blue-active;
  }

  svg {
    fill: palette.$white !important;
  }

  &.cds--overflow-menu--open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    svg {
      fill: palette.$black !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
}

.cds--overflow-menu-options {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;

  button:focus {
    outline: none;
  }
}

.cds--structured-list .cds--structured-list-row {
  outline: none;

  .cds--structured-list-td {
    border-top: none;
  }
}

.cds--btn--danger {
  background-color: palette.$red;
}

.cds--btn--ghost:focus {
  box-shadow: none;
}

.cds--btn:focus {
  box-shadow: none;
}

.cds--checkbox:checked + .cds--checkbox-label::before,
.cds--checkbox:indeterminate + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state="true"]::before {
  background-color: palette.$blue;
}

.cds--checkbox-label::before {
  border-radius: 1.5px;
}

.cds--checkbox:focus + .cds--checkbox-label::before,
.cds--checkbox-label__focus::before,
.cds--checkbox:checked:focus + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state="true"].cds--checkbox-label__focus::before,
.cds--checkbox:indeterminate:focus + .cds--checkbox-label::before {
  outline: none;
}

label.cds--label {
  margin-top: 0.125rem;
}

.cds--header__name {
  cursor: pointer;
}

@media (min-width: 82rem) {
  .cds--modal .cds--modal-container {
    width: 60%;
  }
}

.cds--number--sm.cds--number input[type="number"] {
  padding-right: 3rem !important;
}

.cds--search {
  max-width: 18rem;

  .cds--search-input {
    background-color: palette.$white;
    border-bottom: none;
    border-radius: 1rem;
    z-index: unset;
  }

  button.cds--search-close {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: none;

    &::before {
      display: none;
    }
  }
}

.cds--modal button.cds--btn {
  border-radius: 0.5rem;
}

textarea.cds--text-area {
  width: 100%;
}

.cds--modal.is-visible .cds--modal-container .cds--modal-content p {
  padding-right: unset;
}

.cds--modal.is-visible .cds--modal-container {
  transform: unset !important;
}

.cds--progress-bar--big .cds--progress-bar__track {
  border-radius: 1rem;
  overflow: hidden;
}

.cds--date-picker.cds--date-picker--range {
  .cds--date-picker-container:first-of-type input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .cds--date-picker-container:last-of-type input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.cds--text-input.cds--text__input:disabled {
  border-bottom: inset;
}

.cds--text-input:disabled {
  border-bottom: 1px solid rgb(230, 230, 237) !important;
}

.cds--label--disabled {
  color: #525252;
}

.cds--form-requirement {
  color: palette.$red;
  margin-left: 0.75rem;
}

// button.cds--btn--icon-only + span.cds--popover {
//   display: none;
// }

.cds--popover {
  z-index: 9999;
}

.cds--dropdown:hover {
  background-color: palette.$off-white;
}

.cds--btn {
  &.cds--btn--md {
    padding: calc(0.875rem - 3px) 31.5px calc(0.875rem - 3px) 31.5px;
  }

  &.cds--btn--sm {
    padding: calc(0.375rem - 3px) 31.5px calc(0.375rem - 3px) 31.5px;
  }
}

.cds--modal-container .cds--modal-header {
  padding-right: 1rem;
}

.cds--btn {
  &:not(.cds--header__action) {
    border-radius: 1rem;
  }

  &.cds--btn--secondary {
    padding: calc(0.875rem - 3px) 31.5px calc(0.875rem - 3px) 31.5px;
    border: 1px solid palette.$blue;
    color: palette.$blue;

    &.cds--btn--md {
      padding: calc(0.875rem - 3px) 31.5px calc(0.875rem - 3px) 31.5px;
    }

    &.cds--btn--sm {
      padding: calc(0.375rem - 3px) 31.5px calc(0.375rem - 3px) 31.5px;
    }

    &:hover {
      border: 1px solid palette.$blue;
    }
  }

  &.cds--btn--secondary.cds--btn--disabled {
    color: palette.$grey-dark;
    border: 1px solid palette.$grey-light;
    background-color: palette.$grey-light;

    &:hover {
      color: palette.$grey-dark;
    }
  }

  &.cds--btn--secondary:focus {
    color: palette.$blue;
  }

  &.cds--btn--secondary:hover {
    color: palette.$white;
  }
}

a.cds--side-nav__link[aria-current="page"]::before,
a.cds--side-nav__link--current::before {
  display: none;
}

.cds--side-nav__overlay.cds--side-nav__overlay-active {
  z-index: 30;
}

.cds--row {
  @media (max-width: 65.98rem) {
    justify-content: center;
  }
}

.cds--link {
  background-color: transparent;
  cursor: pointer;
}

.cds--radio-button:checked + .cds--radio-button__label {
  .cds--radio-button__appearance {
    border-color: palette.$blue !important;

    &::before {
      background-color: palette.$blue !important;
      transform: scale(0.7) !important;
    }
  }
}

.cds--radio-button:focus
  + .cds--radio-button__label
  .cds--radio-button__appearance {
  outline: none !important;
}

.cds--row {
  justify-content: flex-start;
}

// Modal

.cds--modal {
  &.is-visible {
    backdrop-filter: blur(3px);
  }

  .cds--modal-container {
    background-color: palette.$white;
  }

  .cds--modal-container {
    @include shadow.shadow(4);
    padding: 1rem;

    overflow: hidden;

    @media (min-width: 42rem) {
      border-radius: 1rem;
    }
  }

  .cds--modal-content {
    margin-bottom: 1rem;

    .cds--btn {
      border-radius: 0.5rem;
    }
  }

  .cds--modal-header__heading {
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  button.cds--modal-close,
  button.cds--modal-close:focus {
    border-color: transparent;
  }

  .cds--modal-close {
    display: none;
  }

  .cds--modal-content::-webkit-scrollbar {
    width: 0.1rem;
  }

  .cds--modal-content::-webkit-scrollbar-track {
    background-color: palette.$grey-light;
  }

  .cds--modal-content::-webkit-scrollbar-thumb {
    background-color: palette.$grey;
  }
}

// Content Switcher

span.cds--content-switcher__label {
  margin: auto;
}

button.cds--content-switcher-btn {
  padding: unset;

  &.cds--content-switcher--selected {
    background-color: palette.$blue;
    box-shadow: none;
  }

  &::after {
    background-color: palette.$blue;
  }

  &:first-child {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  &:last-child {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

// Inputs general

.cds--date-picker.cds--date-picker--single .cds--date-picker__input,
textarea.cds--text-area,
.cds--text-input.cds--text-input--md,
.cds--text-input.cds--text__input,
.cds--date-picker__input.cds--date-picker__input--md,
.cds--text-input.cds--text-input--sm,
.cds--dropdown,
select.cds--select-input {
  border-radius: 1rem;
  border: 1px solid palette.$grey-light;
  background-color: palette.$white;

  &:focus {
    outline: none;
  }
}

.cds--modal {
  .cds--text-input,
  .cds--text-area,
  .cds--search-input,
  .cds--text-input.cds--text-input--sm,
  .cds--text-input.cds--text-input--md,
  .cds--text-input.cds--text-input--lg,
  .cds--date-picker-input__wrapper input,
  .cds--dropdown.cds--list-box,
  .cds--select-input {
    border-radius: 0.25rem;
  }
}

.ReactModalPortal .ril__caption,
.ReactModalPortal .ril__toolbar {
  background-color: transparent;
}

.ReactModalPortal .ril__outer {
  background-color: rgba(22, 22, 22, 0.8);
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99999 !important;
}

// Apex charts
#root {
  .apexcharts-tooltip.apexcharts-theme-light {
    border: none;
    border-radius: 1rem;

    .apexcharts-tooltip-title {
      padding-left: 1rem;
      background-color: palette.$off-white;
      font-weight: 600;
      border: none;
    }
  }
}
