$sizes: 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3;

@mixin spacingGen($prefix, $property) {
  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    $name: $size * 100;

    .#{$prefix}-#{$name} {
      #{$property}: #{$size}rem;
    }
  }
}

@mixin staticSpacing() {
  .m-a {
    margin: auto;
  }

  .ml-a {
    margin-left: auto;
  }

  .mr-a {
    margin-right: auto;
  }

  .mtn-100 {
    margin-top: -1rem;
  }

  .mtn-050 {
    margin-top: -0.5rem;
  }

  .mln-100 {
    margin-left: -1rem;
  }

  .mrn-200 {
    margin-right: -2rem;
  }

  .pl-25 {
    padding-left: 0.25rem;
  }

  .pb-050 {
    padding-bottom: 0.5rem;
  }
}
