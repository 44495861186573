.tc-flex-j-center {
  display: flex;
  justify-content: center;
}

.tc-flex-j-end {
  display: flex;
  justify-content: flex-end;
}

.tc-flex-j-between {
  display: flex;
  justify-content: space-between;
}

.tc-flex-a-center {
  display: flex;
  align-items: center;
}

.tc-flex-a-top {
  display: flex;
  align-items: flex-start;
}

.tc-flex-a-bottom {
  display: flex;
  align-items: flex-end;
}

.tc-flex-shrink-0 {
  flex-shrink: 0;
}

.tc-flex-d-col {
  display: flex;
  flex-direction: column;
}
