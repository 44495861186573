@use "../../../styles/palette";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-decoration: none;
    transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);

    img {
      margin: 1.25rem 0 0 1.25rem;
      max-height: 40px;
    }

    span {
      font-size: 2.375rem;
      font-weight: 100;
      color: palette.$white;
      line-height: 1.75rem;
      margin-left: 1rem;
    }

    &:hover {
      opacity: 0.825;
    }
  }
}
